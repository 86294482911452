import moment from 'moment'

moment.locale('fr')

export const amazonBucket = {
  link: window.AlphormConfiguration.AmazonBucket.Link,
  avatar: window.AlphormConfiguration.AmazonBucket.Avatar,
  bucketBaseUrl: window.AlphormConfiguration.AmazonBucket.BucketBaseUrl,
  categories: window.AlphormConfiguration.AmazonBucket.Categories,
  categoryPath: window.AlphormConfiguration.AmazonBucket.CategoryPath,
  UploadPhoto: window.AlphormConfiguration.AmazonBucket.UploadPhoto
}
const { ApiUrl } = window.AlphormConfiguration

export const BASE_URL_WEB = `${ApiUrl}/web`

export const {
  AlphormPlayerScript,
  LinkAppAndroid,
  LinkAppIOS,
  OfflinePlayerLinks,
  FacebookLink,
  TwitterLink,
  InstagramLink,
  FacebookShareLink,
  TwitterShareLink,
  LinkdinShareLink,
  CertificatLink,
  RecaptchaSiteKey,
  FrontUrl,
  StripePublicKey,
  IsFreePeriod,
  LinkedinLink
} = window.AlphormConfiguration

export const ProfileTypes = {
  Default: 'Default',
  FreeSubscriber: 'FreeSubscriber',
  Buyer: 'Buyer',
  BasicSubscriber: 'BasicSubscriber',
  PremiumSubscriber: 'PremiumSubscriber'
}

export const Avatars = {
  anonymous: window.AlphormConfiguration.Avatars.Anonymous,
  female: window.AlphormConfiguration.Avatars.Female,
  male: window.AlphormConfiguration.Avatars.Male
}

export const billingCycleId = {
  annual: 1,
  monthly: 2,
  license: 4,
  trialannual: 5,
  trialmonthly: 6
}

export const OnBoardingStateEnum = {
  Start: 'Start',
  Completed: 'Completed'
}

export const osTypes = {
  android: 'AndroidOS',
  ios: 'iOS'
}

export const UserRoles = {
  Admin: 'Admin',
  OrganizationAdmin: 'OrganizationAdmin',
  OrganizationLearner: 'OrganizationLearner',
  OrganizationManager: 'OrganizationManager',
  Rep: 'Rep'
}

export const categoriesColor = {
  'e930ec21-08e6-4b42-a530-02f550265083': {
    backgroundColor: '#FAD1DB',
    primaryColor: 'rgba(233,27,75,0.99)'
  },
  'd7305f3f-ab63-4f23-abe2-3662fff390f2': {
    backgroundColor: '#ffe3d8',
    primaryColor: '#FA6400'
  },
  'ba45ab70-968f-4add-8756-35de31f325c0': {
    backgroundColor: '#dcf4ea',
    primaryColor: '#02C58C'
  },
  'a809f91b-244d-4183-8793-6faf329e7693': {
    backgroundColor: '#E0D7FF',
    primaryColor: '#6236FF'
  },
  '9d797625-4d1c-4a24-afa4-73b94eddeca3': {
    backgroundColor: '#ffe3d8',
    primaryColor: '#FA6400'
  },
  '977a26d2-89f2-41d5-be90-6a84365aabb3': {
    backgroundColor: '#d9eaff',
    primaryColor: '#0091FF'
  },
  '19bb7b7a-f80c-46ba-93d1-c52ea9d8f26e': {
    backgroundColor: '#FDF0CC',
    primaryColor: '#F7B500'
  },
  '0df9e561-41d4-4e5f-8bff-2c92ddfa9903': {
    backgroundColor: '#dbd5f8',
    primaryColor: '#3709D8'
  },
  'other-webinars-id': {
    backgroundColor: '#B3C8FA'
  }
}

export const RatingColor = {
  5: '#02C58C',
  4: '#B7EA83',
  3: '#F6D757',
  2: '#FBB851',
  1: '#F17A54'
}

export const CourseProgressionState = {
  NA: 'NA',
  Start: 'Start',
  Continue: 'Continue',
  Done: 'Done'
}

export const ItemTypesEnum = {
  Course: 'Course',
  Subscription: 'Subscription'
}

export const paymentMethodTypes = {
  1: 'CB',
  2: 'CB',
  7: 'Paypal'
}

export const paymentMethodTypesDataLayer = {
  1: 'CB',
  2: 'CB',
  7: 'Paypal'
}

export const couponNotAppliedDataLayer = 'Not Applied'

export const ItemTypesDataLayer = {
  course: 'FORMATION',
  subscription: 'ABONNEMENT'
}

export const productsNameDataLayer = {
  [`${billingCycleId.annual}`]: 'Abonnement Annuel',
  [`${billingCycleId.monthly}`]: 'Abonnement Mensuel'
}

export const productsIdDataLayer = {
  [`${billingCycleId.annual}`]: 'ABNAN',
  [`${billingCycleId.monthly}`]: 'ABNMEN'
}

export const profileTypesDataLayer = {
  0: 'Gratuit',
  1: 'Gratuit',
  2: 'Buyer',
  3: 'Abonné',
  4: 'Abonné'
}

export const defaultProfileTypeDataLayer = 0

const startYear = 1900
const endYear = moment().year()
export const months = moment.months().map((month, i) => ({ Id: i, Value: month }))
export const rangeOfYears = () => Array(endYear - startYear + 1)
  .fill(startYear)
  .map((year, index) => ({ Id: year + index, Value: String(year + index) }))
  .reverse()

export const MailEventTypesEnum = {
  ContactFinancing: 'ContactFinancing',
  ContactSupport: 'ContactSupport'
}

export const ExternalAlphormPages = {
  BusinessOffer: 'https://blog.alphorm.com/entreprise/',
  FAQ: 'https://support.alphorm.com/hc/fr/categories/200194062',
  BecomeTrainer: 'https://pages.alphorm.com/formateur/',
  Support: 'https://support.alphorm.com/hc/fr',
  Funding: 'https://blog.alphorm.com/financement-cpf-alphorm/',
  AlgerianBusinessOffer: 'https://blog.alphorm.com/entreprise_algerie/',
  NewCoursesIdeas: 'https://support.alphorm.com/hc/fr/community/topics/200127952-Idées-de-nouvelles-formations',
  Blog: 'https://blog.alphorm.com/'
}

export const AlgeriaId = 4

export const B2BContactInfo = {
  ContactPhone: window.AlphormConfiguration.OrganizationAssets?.ContactPhone
}

export const InvoiceContactInfo = {
  ContactPhone: window.AlphormConfiguration.Phone,
  SupportEmail: window.AlphormConfiguration.SupportEmail
}

export const allCoursesId = 1
export const allLearningPathId = 2

export const defaultCountry = { Id: '-1', Name: '' }

export const CheckPhoneNumberStatus = {
  Success: 0,
  Error: 1,
  NumberNotCorrect: 2
}

export const platformTypes = {
  windows: 'Windows',
  macOS: 'MacOS',
  linux: 'Linux'
}

export const offlinePlayerLinksByPlatform = {
  [platformTypes.windows]: OfflinePlayerLinks?.Windows,
  [platformTypes.macOS]: OfflinePlayerLinks?.Mac,
  [platformTypes.linux]: OfflinePlayerLinks?.Debian
}

export const ReviewStatusEnum = {
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected'
}

export const ReviewsEnum = {
  Content: 'Content',
  Duration: 'Duration',
  Level: 'Level',
  TroubleVideoAudio: 'TroubleVideoAudio',
  Accent: 'Accent',
  MissingInfos: 'MissingInfos',
  Style: 'Style'
}

export const ReviewsScoresEnum = {
  Bad: 'Bad',
  Good: 'Good',
  Neutral: 'Neutral',
  Skipped: 'Skipped'
}

export const OnboardingVideos = {
  FreeSubscriber: 'https://static.alphorm.com/onboarding/FreeSubscriber.mp4',
  FreeSubscriberCPF: 'https://static.alphorm.com/onboarding/FreeSubscriberCPF.mp4',
  BasicSubscriber: 'https://static.alphorm.com/onboarding/BasicSubscriber.mp4',
  Buyer: 'https://static.alphorm.com/onboarding/Buyer.mp4',
  InvitedB2BLearner: 'https://static.alphorm.com/onboarding/InvitedB2BLearner.mp4',
  B2BLearnerWithoutLicense: 'https://static.alphorm.com/onboarding/B2BLearnerWithoutLicense.mp4',
  B2BLearnerWithLicense: 'https://static.alphorm.com/onboarding/B2BLearnerWithLicense.mp4'
}

export const FranceCountryId = 65

export const quizResultStatusEnum = {
  succeeded: 'succeeded',
  failed: 'failed'
}

export const OnboardingStepStatus = {
  active: 'active',
  inactive: 'inactive',
  done: 'done'
}

export const showLearningPath = window.AlphormConfiguration.Features?.LearningPath
export const threeTimesPaymentFeature = window.AlphormConfiguration.Features?.ThreeTimesPayment
export const freeTrialFeature = window.AlphormConfiguration.Features?.FreeTrial
export const projectSectionFeature = window.AlphormConfiguration.Features?.Project
export const WebinarFeature = window.AlphormConfiguration.Features?.Webinar

export const previousOnboardingStep = -1
export const lastOnboardingStep = -1

export const minPriceForThreeTimesPayment = 300

export const popUpDefaultTimeOut = 2000

export const countDownString = '$count-down$'

export const defaultUserType = 11

export const morrocoShopId = window.AlphormConfiguration.MoroccanShopId

export const videoSources = {
  HD: '720p',
  SD: '540p',
  FHD: '1080p',
  LD: '360p'
}

export const paymentCardTypes = {
  Visa: 1,
  Mastercard: 2,
  PayPal: 7,
  CmiCard: 9
}

export const takeAll = 0
export const skipNone = 0

export const digitsToShow = 2
export const codeLength = 6
export const errors = {
  notValid: 'notValid',
  valid: 'valid',
  notSet: 'notSet'
}
