import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import styles from './adCardCpf.styles'
import classNames from 'classnames'
import TypographyElement from '../typographyElement/typographyElement'
import Skeleton from '../skeleton/skeleton'
import Icon from '../icon/icon'
import { Icons } from 'UI/constants'

const LoadingSkeleton = ({
  className,
  width,
  secondary,
  margin,
  tabletMargin,
  mobileMargin,
  noFlex
}) => {
  const theme = useTheme()
  const useStyle = createUseStyles(styles)
  const { root } = useStyle({
    width,
    shadowColor: theme.neutral[0],
    secondary,
    margin,
    tabletMargin,
    mobileMargin,
    noFlex
  })
  return (
    <div className={classNames(root, className)}>
      <Skeleton lines={1} width='100%' height={41} margin='0 0 12px 0' />
      <Skeleton lines={4} width='100%' height={31} gap={4} margin='0 0 16px 0' />
      <Skeleton lines={1} width='100%' height={54} />
    </div>
  )
}

const AdCard = ({ title, infos, buttonLabel, onButtonClick, width, shadowColor, className, secondary, margin, tabletMargin, mobileMargin, noFlex, loading, href }) => {
  const theme = useTheme()
  const useStyle = createUseStyles(styles)
  const { root, button, iconStyle, checkIconStyle, rowStyle } = useStyle({ width, shadowColor, secondary, margin, tabletMargin, mobileMargin, noFlex })

  if (loading) {
    return (
      <LoadingSkeleton
        className={className}
        width={width}
        shadowColor={shadowColor}
        secondary={secondary}
        margin={margin}
        tabletMargin={tabletMargin}
        mobileMargin={mobileMargin}
        noFlex={noFlex}
      />
    )
  }

  return (
    <div className={classNames(root, className)}>
      <TypographyElement
        variant='heading3'
        spacing='0 0 8px 0'
        spacingTablet='0 0 40px 0'
        spacingMobile='0 0 40px 0'
      >
        {title}
      </TypographyElement>
      {
        infos.map((info, index) => (
          <div key={index} className={rowStyle}>
            <Icon iconName={Icons.check} style={checkIconStyle} />
            <TypographyElement variant='body1'>{info}</TypographyElement>
          </div>
        ))
      }
      <a href={href} onClick={(e) => onButtonClick && onButtonClick(e)} target='_blank' className={button} rel='noreferrer'>
        <TypographyElement variant='button' color={theme.primary[0]}>{buttonLabel}</TypographyElement>
        <Icon iconName={Icons.arrowRight} style={iconStyle} />
      </a>
    </div>
  )
}

AdCard.propTypes = {
  /** Title of the card */
  title: PropTypes.string,
  /** Core of the card */
  core: PropTypes.string,
  /** Label of the button */
  buttonLabel: PropTypes.string,
  /** Function to execute when button is clicked */
  onButtonClick: PropTypes.func,
  /** Width of the card */
  width: PropTypes.string,
  /** Color of the shadow */
  shadowColor: PropTypes.string,
  /** Classname of the card */
  className: PropTypes.string,
  /** Secondary color of the card */
  secondary: PropTypes.bool,
  /** Margin of the card */
  margin: PropTypes.string,
  /** Margin of the card on tablet */
  tabletMargin: PropTypes.string,
  /** Margin of the card on mobile */
  mobileMargin: PropTypes.string
}

export default AdCard
