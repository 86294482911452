export default {
  googlePlayClass: {
    width: 140,
    height: 47
  },
  appDesktopClass: {
    width: 140,
    height: 47
  },
  appStoreClass: {
    width: 140,
    height: 47,
    cursor: 'pointer'
  }
}
