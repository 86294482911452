import React, { useState, useRef, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { courseProgressionHeaderStyle } from './courseProgressionHeader.style'
import { Icon } from '../icon/icon'
import { Icons } from '../../constants/icons'
import TypographyElement from '../typographyElement/typographyElement'

const CourseProgressionHeader = ({ handleChapterClick, chapterIndex, chapterTitle, children, expand }) => {
  const useStyle = createUseStyles(courseProgressionHeaderStyle)
  const [open, setOpen] = useState(expand)
  const [height, setHeight] = useState(0)
  const ref = useRef()
  useLayoutEffect(() => { ref.current && ref.current.clientHeight && setHeight(open ? ref.current.clientHeight : 0) })
  useEffect(() => { setOpen(expand) }, [expand])
  const { chaptersGroup, chapterHeader, showIcon, videosGroup } = useStyle({ open, chapterIndex })
  const chapterClick = () => {
    handleChapterClick && handleChapterClick()
    setOpen(!open)
  }
  return (
    <div className={chaptersGroup}>
      <div className={chapterHeader} onClick={() => chapterClick()}>
        <TypographyElement variant='heading5'> {chapterIndex}. {chapterTitle} </TypographyElement>
        <Icon iconName={Icons.roundedDown} style={showIcon} />
      </div>
      <div className={videosGroup} style={{ height }}>
        <div ref={ref}>
          {children}
        </div>
      </div>
    </div>
  )
}

CourseProgressionHeader.propTypes = {
  /** the index of the chapter */
  chapterIndex: PropTypes.number,
  /** the title of a chapter */
  chapterTitle: PropTypes.string,
  /** expand chapter from the parent */
  expand: PropTypes.bool,
  /** function that handle the click on a chapter */
  handleChapterClick: PropTypes.func,
  /** children of the chapter */
  children: PropTypes.node
}

CourseProgressionHeader.defaultProps = {
  expand: true
}

export default CourseProgressionHeader
