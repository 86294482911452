
import {
  googlePlay,
  appDesktop,
  appStore
} from 'assets'
import { createUseStyles, useTheme } from 'react-jss'
import style from './style'
import Text from '../text.json'
import { chunk } from 'lodash'
import { PricingPath, Dashboard, LearningPath, ContactPath, CategoryNeutralPath, OfflinePlayerPath } from 'navigation/Routes'
import { ExternalAlphormPages, LinkAppAndroid, LinkAppIOS } from 'constants/'
import { decorateUrl } from 'components/common/UTMscript'
import { Col, Container, Row, TypographyElement } from 'UI'

const useStyle = createUseStyles(style)

const Title = ({ children }) => {
  const theme = useTheme()
  return (
    <TypographyElement
      spacing='0 0 16px 0'
      color={theme.primary[200]}
      variant='heading6'
      fontSize='19px'
      lineHeight='24px'
    >
      {children}
    </TypographyElement>
  )
}

const SubTitle = ({ children, handleClick, href, target, spacing }) => {
  const theme = useTheme()
  return (
    <TypographyElement
      color={theme.neutral[100]}
      cursor='pointer'
      variant='body1'
      handleClick={e => {
        handleClick && !target && e.preventDefault()
        handleClick && !target && handleClick && handleClick()
      }}
      href={decorateUrl(href)}
      Component={href && 'a'}
      rel={!!href}
      target={target}
      spacing={spacing}
    >
      {children}
    </TypographyElement>
  )
}

export default ({ isConnected, france, email, phone, categories, goToUrl, activeSubscription, isAlgeria }) => {
  const { googlePlayClass, appDesktopClass, appStoreClass } = useStyle()
  const categoriesChunks = categories && categories.length ? chunk(categories, Math.ceil(categories.length / 2)) : []
  return (
    <Container>
      <Row align='center'>
        <Col grid={2} pos='left' direction='column'>
          <Title>{Text.courses} </Title>
          {
            categories.length
              ? categoriesChunks.at(0).map((category, key) =>
                <SubTitle
                  key={category.Id}
                  handleClick={() => goToUrl(`${CategoryNeutralPath}/${category.Slug}`)}
                  href={`${CategoryNeutralPath}/${category.Slug}`}
                >
                  {category.Name}
                </SubTitle>
                )
              : null
          }
        </Col>
        <Col grid={2} pos='left' direction='column'>
          {
            categories.length
              ? categoriesChunks.at(1).map((category, key) =>
                <SubTitle
                  spacing={key === 0 ? '40px 0 0 0' : null}
                  key={category.Id}
                  handleClick={() => goToUrl(`${CategoryNeutralPath}/${category.Slug}`)}
                  href={`${CategoryNeutralPath}/${category.Slug}`}
                >
                  {category.Name}
                </SubTitle>
                )
              : null
          }
        </Col>
        <Col grid={2} pos='left' direction='column'>
          <Title>{Text.solutions}</Title>
          {
            !activeSubscription
              ? (
                <>
                  <SubTitle handleClick={() => goToUrl(PricingPath)} href={PricingPath}>{Text.pricing}</SubTitle>
                  <SubTitle href={isAlgeria ? ExternalAlphormPages.AlgerianBusinessOffer : ExternalAlphormPages.BusinessOffer} target='_blank'>{Text.offers}</SubTitle>
                  {france && <SubTitle href={ExternalAlphormPages.Funding} target='_blank'>{Text.funding}</SubTitle>}
                </>
                )
              : null
          }
          <SubTitle href={ExternalAlphormPages.BecomeTrainer} target='_blank'>{Text.becomeTutor}</SubTitle>
          <SubTitle href={ExternalAlphormPages.Blog} target='_blank'>{Text.blog}</SubTitle>
        </Col>
        {
          isConnected &&
          (
            <Col grid={2} pos='left' direction='column'>
              <Title>{Text.mySpace}</Title>
              <SubTitle handleClick={() => goToUrl(Dashboard)} href={Dashboard}>{Text.account}</SubTitle>
              <SubTitle handleClick={() => goToUrl(LearningPath)} href={LearningPath}>{Text.learning}</SubTitle>
            </Col>
          )
        }
        <Col grid={2} pos='left' direction='column'>
          <Title>{Text.support}</Title>
          <SubTitle href={ExternalAlphormPages.Support} target='_blank'>{Text.help}</SubTitle>
          <SubTitle handleClick={() => goToUrl(ContactPath)} href={ContactPath}>{Text.contactUs}</SubTitle>
          <SubTitle href={ExternalAlphormPages.FAQ} target='_blank'>{Text.FAQ}</SubTitle>
          <SubTitle href={`tel:${phone}`}>{phone}</SubTitle>
          <SubTitle href={`mailto:${email}`}>{email}</SubTitle>
        </Col>
        <Col grid={isConnected ? 2 : 4} direction='column' align='bot'>
          <a target='_blank' rel='noopener noreferrer' href={LinkAppIOS}><img src={appStore} alt='appStore' className={appStoreClass} loading='lazy' /></a>
          <a target='_blank' rel='noopener noreferrer' href={LinkAppAndroid}><img src={googlePlay} alt='googlePlay' className={googlePlayClass} loading='lazy' /></a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={OfflinePlayerPath}
            onClick={(e) => {
              e.preventDefault()
              goToUrl(OfflinePlayerPath)
            }}
          ><img src={appDesktop} alt='appDesktop' className={appDesktopClass} loading='lazy' />
          </a>
        </Col>
      </Row>
    </Container>
  )
}
